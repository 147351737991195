import React from "react";
import axios from "axios";
import Api from "../../Api/Api.js";
import { URI } from "../../Api/index.js";
import { isNullRetNull, splitArrayIntoChunks } from "../../utils/index.js";
import Loader from "../../Components/Loader/index.js";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import Notify from "../../Components/Notify/index.js";
import { Link } from "react-router-dom";
//import Iframe from 'react-iframe'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class CategoryPosts extends React.Component {
  state = {
    posts: [],
    baseUrl: "",
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
    const baseUrl = "http://quews.smartmediahouse.tv";
    //  const baseUrl = "http://quews.smartmediahouse.tv";

    const cat_id = this.props.match.params.cat_id;
     
    //console.log(this.props.match.params);
    this.setState({
      baseUrl:`${baseUrl}/quews/`,  
    });
 
    axios
      .get(`${baseUrl}/quews/Api_data/getPostByCatIds?cat_id=${cat_id}&limit=20`)
      .then((res) => {
        const posts = res.data;
        this.setState({ posts });
        this.setState({ loaded: false });
        
      });
  }

  render() {
    const { posts, baseUrl, loaded } = this.state;
    //console.log(posts);
    return (
      <>
      {loaded ? (
        <Loader isLoader={loaded}></Loader>
      ) : (
      <>
        <div className="container-fluid pt-3 source-data paddT">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
              <h2>{posts.data && posts.data[0].category_name}</h2>
            </div>
            <div className="row mx-0 news-main">
              {posts.data &&
                posts.data.map((row) => {
                  return (
                    <>
                      <div className="col-md-3 col-12 paddding-l">
                        <Link to={`/news/${row.posts_id}`}> 
                          <div className="x-bg">
                            <div className="x-bg-layer-lower-img">
                                <img src={`${baseUrl}${row.image_link && row.image_link}`} alt="img"  />
                            </div>
                        </div>
                          <h6>{row.post_title}</h6>
                          <p>{row.content.substring(0, 100)}</p>
                        </Link>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div> </>
      )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPosts);
