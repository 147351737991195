import React from "react";
import axios from "axios";
import moment from "moment";
import Api from "../../Api/Api.js";
import { URI } from "../../Api/index.js";
import { isNullRetNull, splitArrayIntoChunks } from "../../utils/index.js";
import Loader from "../../Components/Loader/index.js";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import Notify from "../../Components/Notify/index.js";
import { Link } from "react-router-dom";
//import Iframe from 'react-iframe'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options = {
  margin: 8,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false, 
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 2,
      },
      700: {
          items: 2,
      },
      1000: {
          items: 3,

      },
      1200: {
          items: 4,

      }
  },
}; 
class Home extends React.Component {
  state = {
    vedios: [],
    latestNews: [],
    randomNews: [],
    editorNews: [],
    topNews: [],
    categories: [],
    globalNewsPosts: [],
    pakistanNewsPosts: [],
    environmentNewsPosts: [],
    businessNewsPosts: [],
    baseUrl: "",
    counter: 1,
    cls: "",
    loaded: false,
  }; 
  componentDidMount() {
    this.setState({ loaded: true });
    const baseUrl = "http://quews.smartmediahouse.tv";
    //  const baseUrl = "http://quews.smartmediahouse.tv";
     
    let one = `${baseUrl}/quews/Api_data/latest_movies_get?limit=10`;
    let two = `${baseUrl}/quews/Api_data/getLatestNews?limit=14`;
    let three = `${baseUrl}/quews/Api_data/getRandumNews`;
    let four = `${baseUrl}/quews/Api_data/getEditorNews`;
    let five = `${baseUrl}/quews/Api_data/getTopNews`;
    let six = `${baseUrl}/quews/Api_data/getAllCategoriesWithPost`;
    let gloablN = `${baseUrl}/quews/Api_data/getNewsByCategory?cat_id=4&limit=15`;    
    let pakistanN = `${baseUrl}/quews/Api_data/getNewsByCategory?cat_id=7&limit=15`;
    let environmentN = `${baseUrl}/quews/Api_data/getNewsByCategory?cat_id=8&limit=15`;
    let businessN = `${baseUrl}/quews/Api_data/getNewsByCategory?cat_id=9&limit=15`;

    const vediosRequest = axios.get(one);
    const latestNewsRequest = axios.get(two);
    const randomNewsRequest = axios.get(three);
    const editorNewsRequest = axios.get(four);
    const topNewRequest = axios.get(five);
    const categoriesRequest = axios.get(six);

    
    const globalNewsPostsRequest = axios.get(gloablN);
    const pakistanNewsPostsRequest = axios.get(pakistanN);
    const environmentNewsPostsRequest = axios.get(environmentN);
    const businessNewsPostsRequest = axios.get(businessN);
    this.setState({
      baseUrl:`${baseUrl}/quews/`, 
      counter:1, 
      cls:"",
    });
    axios
      .all([
        vediosRequest,
        latestNewsRequest,
        randomNewsRequest,
        editorNewsRequest,
        topNewRequest,
        categoriesRequest,        
        globalNewsPostsRequest,
        pakistanNewsPostsRequest,
        environmentNewsPostsRequest,
        businessNewsPostsRequest,
        ,
      ])
      .then(
        axios.spread((...responses) => {
          const vediosResponse = responses[0];
          const latestNewsResponse = responses[1];
          const randomNewsRespones = responses[2];
          const editorNewsRespones = responses[3];
          const topNewRequest = responses[4];
          const categoriesResponse = responses[5]; 
          const globalNewsPostsResponse = responses[6];
          const pakistanNewsPostsResponse = responses[7];
          const environmentNewsPostsResponse = responses[8];
          const businessNewsPostsResponse = responses[9];

          this.setState({
            vedios: vediosResponse.data,
            latestNews: latestNewsResponse.data,
            randomNews: randomNewsRespones.data,
            editorNews: editorNewsRespones.data,
            topNews: topNewRequest.data,
            categories: categoriesResponse.data,

            
            globalNewsPosts: globalNewsPostsResponse.data,
            pakistanNewsPosts: pakistanNewsPostsResponse.data,
            environmentNewsPosts: environmentNewsPostsResponse.data,
            businessNewsPosts: businessNewsPostsResponse.data, 

            loaded: false,
          });

          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
      });
  }

  render() {
    const { vedios, latestNews, randomNews, editorNews, topNews, categories,  globalNewsPosts,  pakistanNewsPosts,  environmentNewsPosts, businessNewsPosts,  baseUrl,counter,cls,
      loaded } =
      this.state;
     //console.log("Global News :::::::", JSON.stringify(globalNewsPosts));
    return (
      <>
      {loaded ? (
        <Loader isLoader={loaded}></Loader>
      ) : (
        <>
        <div className="container paddding-l mb-5-1 mt mt-3">
          <div className="row mx-0 padd-l date mt-2 mb-2">
            {moment().format("dddd,  Do MMMM YYYY")}
          </div>
          <div className="row mx-0">
            <div
              className="col-md-6 col-12 paddding-l animate-box"
              data-animate-effect="fadeIn"
            >
              <div className="fh5co_suceefh5co_height">
                 <Link
                      to={`/news/${topNews.data && topNews.data[0].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${topNews.data && topNews.data[0].image_link}`} alt="img"  />
                          </div>
                      </div>
                </Link>
                <div className="fh5co_suceefh5co_height_position_absolute"></div>
                <div className="fh5co_suceefh5co_height_position_absolute_font">
                  <div className="">
                    <Link
                      to={`/news/${topNews.data && topNews.data[0].posts_id}`}
                      className="fh5co_good_font"
                    >
                      {topNews.data && topNews.data[0].post_title}
                    </Link>
                  </div>
                  <div className="cat">
                    <Link to="categories">All News</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div
                  className="col-md-6 col-6 paddding-l pad-b-8 animate-box"
                  data-animate-effect="fadeIn"
                >
                  <div className="fh5co_suceefh5co_height_2 mb-2"> 
                    <Link
                      to={`/news/${topNews.data && topNews.data[0].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[0].image_link}`} alt="img"  />
                          </div>
                      </div>
                    </Link> 
                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[0].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[0].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Pakistan</Link>
                      </div>
                    </div>
                  </div>
                  <div className="fh5co_suceefh5co_height_2"> 
                     <Link
                      to={`/news/${latestNews.data && latestNews.data[1].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[1].image_link}`} alt="img"  />
                          </div>
                      </div>
                    </Link> 

                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[1].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[1].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Pakistan</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 col-6 paddding-l pad-b-8 animate-box"
                  data-animate-effect="fadeIn"
                >
                  <div className="fh5co_suceefh5co_height_2 mb-2">
                     
                    <Link
                      to={`/news/${latestNews.data && latestNews.data[2].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[2].image_link}`} alt="img"  />
                          </div>
                      </div>
                    </Link> 
                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[2].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[2].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Global</Link>
                      </div>
                    </div>
                  </div>
                  <div className="fh5co_suceefh5co_height_2 mb-2"> 
                    <Link
                      to={`/news/${latestNews.data && latestNews.data[3].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[3].image_link}`} alt="img"  />
                          </div>
                      </div>
                    </Link> 
                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[3].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[3].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Global</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid pt-3 source-data categoryNews">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
              <h2>Global News</h2>
            </div>
            <div className="row mx-0 news-main">



            
            {globalNewsPosts.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {globalNewsPosts.data.map((news) => { 


            return ( 
              <div className="item px-2"> 



                  <div className="fh5co_suceefh5co_height_2 mb-2">
                    <Link to={`/news/${news.posts_id}`} className="postImg"> 
                       <div className="x-bg">
                           <div className="x-bg-layer-lower-img">
                                <img src={`${baseUrl}${news.image_link}`} alt="img"  />
                           </div>
                       </div>
                     </Link> 
                     <Link to={`/news/${news.posts_id}`} className="fh5co_good_font_2"> 
                        <h6>{news.post_title}</h6></Link>
                        <p>{news.except}</p>
		  
                   </div>

                   </div>
 
            );
          })}
        </OwlCarousel>
      )}
  
            </div>
          </div>
        </div>
 



        <div className="container-fluid pt-3 source-data categoryNews">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
              <h2>Pakistan News</h2>
            </div>
            <div className="row mx-0 news-main">



            
            {pakistanNewsPosts.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {pakistanNewsPosts.data.map((news) => { 


            return ( 
<div className="item px-2"> 



<div className="fh5co_suceefh5co_height_2 mb-2">
  <Link to={`/news/${news.posts_id}`} className="postImg"> 
     <div className="x-bg">
         <div className="x-bg-layer-lower-img">
              <img src={`${baseUrl}${news.image_link}`} alt="img"  />
         </div>
     </div>
   </Link> 
   <Link to={`/news/${news.posts_id}`} className="fh5co_good_font_2"> 
      <h6>{news.post_title}</h6></Link>
      <p>{news.except}</p>

 </div>

 </div>




 
            );
          })}
        </OwlCarousel>
      )}
  
            </div>
          </div>
        </div>
 



        <div className="container-fluid pt-3 source-data categoryNews">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
              <h2>Enviornment</h2>
            </div>
            <div className="row mx-0 news-main">



            
            {environmentNewsPosts.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {environmentNewsPosts.data.map((news) => { 


            return ( 
              <div className="item px-2"> 



                  <div className="fh5co_suceefh5co_height_2 mb-2">
                    <Link to={`/news/${news.posts_id}`} className="postImg"> 
                       <div className="x-bg">
                           <div className="x-bg-layer-lower-img">
                                <img src={`${baseUrl}${news.image_link}`} alt="img"  />
                           </div>
                       </div>
                     </Link> 
                     <Link to={`/news/${news.posts_id}`} className="fh5co_good_font_2"> 
                        <h6>{news.post_title}</h6></Link>
                        <p>{news.except}</p>
		  
                   </div>

                   </div>
 
            );
          })}
        </OwlCarousel>
      )}
  
            </div>
          </div>
        </div>
 




        <div className="container-fluid pt-3 source-data categoryNews">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
              <h2>Business</h2>
            </div>
            <div className="row mx-0 news-main">



            
            {businessNewsPosts.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {businessNewsPosts.data.map((news) => { 


            return ( 
              <div className="item px-2"> 



                  <div className="fh5co_suceefh5co_height_2 mb-2">
                    <Link to={`/news/${news.posts_id}`} className="postImg"> 
                       <div className="x-bg">
                           <div className="x-bg-layer-lower-img">
                                <img src={`${baseUrl}${news.image_link}`} alt="img"  />
                           </div>
                       </div>
                     </Link> 
                     <Link to={`/news/${news.posts_id}`} className="fh5co_good_font_2"> 
                        <h6>{news.post_title}</h6></Link>
                        <p>{news.except}</p>
		  
                   </div>

                   </div>
 
            );
          })}
        </OwlCarousel>
      )}
  
            </div>
          </div>
        </div>
 





        <div className="container-fluid fh5co_video_news_bg pb-5 vid-bg">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div>
              <div className="fh5co_heading pt-5 pb-2">
                <h2>Watch It</h2>
              </div>
            </div>

            <div className="row mx-0">
            {vedios.data && (
        <OwlCarousel 
        items={3}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {vedios.data.map((ved) => {
           // const { id, heading, description, img, smallHeading } = item;
            return (
              <div className="item px-2"> 
                          <div className="fh5co_hover_news_img">
                            <div className="fh5co_hover_news_img_video_tag_position_relative">
                              <div className="fh5co_news_img">
                                <iframe
                                  src={`https://www.youtube.com/embed/${ved.slug}`}
                                  width="100%"
                                  height="100%"
                                  style={{ border: "none", overflow: "hidden" }}
                                  scrolling="no"
                                  frameBorder="0"
                                  allowFullScreen
                                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                ></iframe>
                              </div>
                            </div>
                            <div className="pt-2">
                              <Link
                                to={`/videos/video/${ved.id}`}
                                className="d-block fh5co_small_post_heading fh5co_small_post_heading_1"
                              >
                                <span className="">{ved.title}</span>
                              </Link>
                              <div className="c_g">
                                <i className="fa fa-clock-o"></i>
                                {ved.release}
                              </div>
                            </div>
                          </div>
                    
                        </div>
            );
          })}
        </OwlCarousel>
      )}

 
            </div>
 
          </div>
        </div>

        <div className="container-fluid pb-4 pt-5 paddding">
          <div className="container paddding">
            <div className="row mx-0">
              <div
                className="col-md-8 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div>
                  <div className="fh5co_heading py-2 mb-2">
                    <h2>Editor's Pick</h2>
                  </div>
                </div>
                <div className="row pb-4">
                  {editorNews.data && (
                    <div className="col-md-12">
                      <div className="fh5co_hover_news_img">
                        
                        <Link
                      to={`/news/${editorNews.data && editorNews.data[0].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${editorNews.data && editorNews.data[0].image_link}`} alt="img"  />
                          </div>
                      </div>
                    </Link> 
                        <div className="e-pick">
                          <h4 className="mt-3">
                            {editorNews.data[0].post_title}
                          </h4>
                          <p>{editorNews.data[0].content.substring(0, 200).replace(/(<([^>]+)>)/gi, "")}</p>
                          <Link
                            to={`/news/${
                              editorNews.data && editorNews.data[0].posts_id
                            }`}
                          >
                            Continue Reading
                            <i className="fa fa-long-arrow-right"></i>
                          </Link>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row mx-0 news-main pt-3 pad-9">
                    {randomNews.data &&
                      randomNews.data.map((rad) => {
                        return (
                          <>
                            <div className="col-md-4 col-12 paddding-l">
                              <Link className="postImg" to={`/news/${rad.posts_id}`}> 
                                <div className="x-bg">
                                <div className="x-bg-layer-lower-img">
                                    <img src={`${baseUrl}${rad && rad.image_link}`} alt="img"  />
                                </div>
                            </div>
                              </Link>                              
                              <h6>{rad.post_title}</h6>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 animate-box sidebarnoPad"
                data-animate-effect="fadeInRight"
              >
                <div className="row pad15">
                  <div className="fh5co_heading py-2 mb-2">
                    <h2>Latest News</h2>
                  </div>
                </div>
                <div className="clearfix"></div>  
                <div className="greenSidebar"> 
                { latestNews.data &&
                  latestNews.data.map((val) => {
                    /*if (counter>1) { 
                      this.setState({
                        cls:'g2',
                        counter: 1,
                      }); 
                    } else {
                      this.setState({
                        cls:'g1',
                        counter: 2,
                      });
                    }
                    */
                    return (
                      <>
                     
                        <div className={`row pb-3 g1`}>
                          <Link to={`/news/${val.posts_id}`}>
                            <div className="col-5 align-self-center">
                              <img src={`${baseUrl}${val && val.image_tiny}`} alt="img" className="fh5co_most_trading" /> 
                            </div>
                            <div className="col-7 paddding">
                              <div className="most_fh5co_treding_font">
                                {val.post_title}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })}
                  </div> 
              </div>
            </div>
          </div>
        </div>
        </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
