import React from "react";
import axios from "axios";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import { Link } from "react-router-dom";
import LivePlayer from "../../Components/LivePlayer/index.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Loader from "../../Components/Loader/index.js";

const options = {
  margin: 8,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false, 
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 2,
      },
      700: {
          items: 2,
      },
      1000: {
          items: 3,

      },
      1200: {
          items: 4,

      }
  },
}; 
class LiveTV extends React.Component {
  state = {
    videoLink: "http://45.76.140.114:8080/hls/mystream.m3u8",
    liveTVData: [], 
    latestNews: [], 
    globalNewsPosts: [],
    baseUrl: "",
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
    const baseUrl = "http://quews.smartmediahouse.tv";
    //const baseUrl = "http://quews.smartmediahouse.tv";
    
    let latestN = `${baseUrl}/quews/Api_data/getLatestVideos?limit=10`;
    let gloablN = `${baseUrl}/quews/Api_data/getVideoByCategory?cat_id=4&limit=10`;  
    let liveTVN = `${baseUrl}/quews/Api_data/getLiveTv`;  
    
    
    const latestNewsRequest = axios.get(latestN);
    const globalNewsPostsRequest = axios.get(gloablN);
    const liveTVRequest = axios.get(liveTVN);
    this.setState({
      baseUrl:`${baseUrl}/quews/`,  
    });
/*
    axios.get(`${baseUrl}/quews/Api_data/getLiveTv`).then((res) => {
      const liveTVData = res.data;

      this.setState({ liveTVData });
    });

*/
    
    axios
      .all([ 
        latestNewsRequest,       
        globalNewsPostsRequest,
        liveTVRequest,  
      ])
      .then(
        axios.spread((...responses) => { 
          const latestNewsResponse = responses[0]; 
          const globalNewsPostsResponse = responses[1];
          const liveTVResponse = responses[2]; 

          this.setState({ 
            latestNews: latestNewsResponse.data, 
            globalNewsPosts: globalNewsPostsResponse.data,
            liveTVData: liveTVResponse.data, 
            loaded: false
          });

          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
      });




  }

  render() {
    const { videoLink, liveTVData, latestNews, globalNewsPosts, baseUrl,loaded } = this.state;
    //console.log(globalNewsPosts);
    return (
      <>
      {loaded ? (
        <Loader isLoader={loaded}></Loader>
      ) : (
      <>
        <div className="PlayerPage">
          <div className="container paddding-l m-vid mb-5 mt mt-3">
            <div className="row mx-0">
              <div className="col-md-12 col-12 paddding-l ">
                <LivePlayer />

                
              <div className="pt-2">  
                <div className="row mx-0">
                  <div className="col-md-8 col-12 paddding-l ">
                    <h2 class="head pt-2">Now Playing</h2> 
                    <p class="epg-container__now-playing__summary css-1wt8oh6">Live, breaking and in-depth news from Asia, Africa, the Middle East, Europe and the Americas.</p>
                    
                  </div>
                  <div className="col-md-4 col-12 paddding-l ">
                  <div className="details">
                      <a href="/underConstration"><div class="epg-container__next__schedule"><svg class="icon icon--schedule icon--primary icon--24 epg-container__next__schedule__icon" viewBox="0 0 20 20" version="1.1" aria-hidden="true"><title>schedule</title><g fill="#036301"><path d="M17.14 2h-1.46a.48.48 0 0 0-.48.49c0 .27.21.48.48.48h1.46c1.05 0 1.9.88 1.9 1.95v12.16c0 1.07-.85 1.95-1.9 1.95H2.86c-1.05 0-1.9-.88-1.9-1.95V4.92c0-1.07.85-1.95 1.9-1.95h1.47c.26 0 .47-.21.47-.48A.48.48 0 0 0 4.33 2H2.86A2.9 2.9 0 0 0 0 4.92v12.16A2.9 2.9 0 0 0 2.86 20h14.28A2.9 2.9 0 0 0 20 17.08V4.92A2.9 2.9 0 0 0 17.14 2"></path><path d="M13.5 3a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0-.5.5c0 .28.23.5.5.5h6zM7 9.5c0-.28-.18-.5-.4-.5H3.4c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M17 9.5c0-.28-.18-.5-.4-.5h-3.2c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M12 9.5c0-.28-.18-.5-.4-.5H8.4c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M7 12.5c0-.28-.18-.5-.4-.5H3.4c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M17 12.5c0-.28-.18-.5-.4-.5h-3.2c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M12 12.5c0-.28-.18-.5-.4-.5H8.4c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M7 15.5c0-.28-.18-.5-.4-.5H3.4c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M17 15.5c0-.28-.18-.5-.4-.5h-3.2c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M12 15.5c0-.28-.18-.5-.4-.5H8.4c-.22 0-.4.22-.4.5s.18.5.4.5h3.2c.22 0 .4-.22.4-.5M6 5.5v-5a.5.5 0 1 0-1 0v5a.5.5 0 1 0 1 0M14.5 0a.5.5 0 0 0-.5.5v5a.5.5 0 1 0 1 0v-5a.5.5 0 0 0-.5-.5"></path></g></svg><div class="epg-container__next__schedule__title">See full schedule</div></div></a>
                    
                    </div>
                  </div>
                  </div>
                </div>

              </div>
            </div>
          </div>





          <div className="container-fluid pt-3 source-data categoryNews">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
              <h2>Latest Videos</h2>
            </div>
            <div className="row mx-0 news-main">

            {latestNews.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {latestNews.data.map((ved) => {
           // const { id, heading, description, img, smallHeading } = item;
            return (
              <div className="item px-2"> 
                          <div className="fh5co_hover_news_img">
                          <Link
                            to={`/videos/video/${ved.videos_id}`}
                            className="postImg">
                            <div className="x-bg">
                                <div className="x-bg-layer-lower-img">
                                      <img src={`${baseUrl}${ved.image_link}`} alt="img"  />
                                </div>
                            </div>
                          </Link> 
                            <div className="pt-2"> 
                              <Link
                                to={`/videos/video/${ved.videos_id}`}
                                className="d-block fh5co_small_post_heading fh5co_small_post_heading_1"
                              >
                                <h5 class="head pt-2">{ved.title}</h5>
                              </Link> 
                            </div>
                          </div>
                    
                        </div>
            );
          })}
        </OwlCarousel>
      )}

            
  
            </div>
          </div>
        </div>
 









        <div className="container-fluid pt-3 source-data categoryNews globalNewsB">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
              <h2>Global Videos</h2>
            </div>
            <div className="row mx-0 news-main">

            {globalNewsPosts.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {globalNewsPosts.data.map((ved) => {
           // const { id, heading, description, img, smallHeading } = item;
            return (
              <div className="item px-2"> 
                          <div className="fh5co_hover_news_img">
                          <Link
                             to={`/videos/video/${ved.videos_id}`}
                            className="postImg">
                            <div className="x-bg">
                                <div className="x-bg-layer-lower-img">
                                      <img src={`${baseUrl}${ved.image_link}`} alt="img"  />
                                </div>
                            </div>
                          </Link> 
                            <div className="pt-2">
                              <Link
                                to={`/videos/video/${ved.videos_id}`}
                                className="d-block fh5co_small_post_heading fh5co_small_post_heading_1"
                              >
                                <h5 class="head pt-2">{ved.title}</h5>
                              </Link> 
                            </div>
                          </div>
                    
                        </div>
            );
          })}
        </OwlCarousel>
      )}

            
  
            </div>
          </div>
        </div>
  
        </div>
        </>
         )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveTV);
