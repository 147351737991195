import React from "react";
import axios from "axios";
import moment from "moment";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import { Link } from "react-router-dom";
import LivePlayer from "../../Components/LivePlayer/index.js";
import Loader from "../../Components/Loader/index.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options = {
  margin: 8,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false, 
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 2,
      },
      700: {
          items: 2,
      },
      1000: {
          items: 3,

      },
      1200: {
          items: 4,

      }
  },
}; 
class WatchVideo extends React.Component {
  state = {
    vedioDetail: [],
    vedios: [],
    latestNews: [], 
    globalNewsPosts: [],
    baseUrl: "",
    sponsorNews: [],
    loaded: false,
  };

  async componentDidMount() {
    this.setState({ loaded: true });
    const baseUrl = "http://quews.smartmediahouse.tv";
    // const baseUrl = "http://quews.smartmediahouse.tv";
    
    let latestN = `${baseUrl}/quews/Api_data/getLatestVideos?limit=10`;
    let gloablN = `${baseUrl}/quews/Api_data/getVideoByCategory?cat_id=4&limit=10`;
    
    
    const latestNewsRequest = axios.get(latestN);
    const globalNewsPostsRequest = axios.get(gloablN);
    this.setState({
      baseUrl:`${baseUrl}/quews/`,  
    });
    const movie_id = this.props.match.params.movie_id;

    await axios
      .get(
        `${baseUrl}/quews/Api_data/get_latest_movie_by_id?movie_id=${movie_id}`
      )
      .then((res) => {
        const vedioDetail = res.data;
        this.setState({ vedioDetail });
      });

    axios.get(`${baseUrl}/quews/Api_data/latest_movies_get`).then((res) => {
      const vedios = res.data;
      this.setState({ vedios });
    });
    axios.get(`${baseUrl}/quews/Api_data/getLatestNews?limit=8`).then((res) => {
      const sponsorNews = res.data;
      this.setState({ sponsorNews });
    });


    axios
      .all([ 
        latestNewsRequest,       
        globalNewsPostsRequest, 
      ])
      .then(
        axios.spread((...responses) => { 
          const latestNewsResponse = responses[0]; 
          const globalNewsPostsResponse = responses[1]; 

          this.setState({ 
            latestNews: latestNewsResponse.data, 
            globalNewsPosts: globalNewsPostsResponse.data, 
            loaded: false
          });

          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
      });






    window.scrollTo(0, 0);
  }

  async displayDetail(movie_id) {
    // const post_id = this.props.match.params.post_id;
    const baseUrl = "http://quews.smartmediahouse.tv";
    await axios
      .get(
        `${baseUrl}/quews/Api_data/get_latest_movie_by_id?movie_id=${movie_id}`
      )
      .then((res) => {
        const vedioDetail = res.data;
        this.setState({ vedioDetail });
      });
    window.scrollTo(0, 0);
  }

  render() {
    const { vedioDetail, vedios, latestNews, globalNewsPosts,sponsorNews, loaded, baseUrl } = this.state;

   ///console.log("vedioDetail ::::::::", vedioDetail);
    return (
      <>
      {loaded ? (
        <Loader isLoader={loaded}></Loader>
      ) : (
      <>
        <div className="PlayerPage">
          
           

          
          <div className="container paddding-l m-vid mb-5 mt mt-3">
            <div className="row mx-0 padd-l date mt-2 mb-2">
              {moment().format("dddd,  Do MMMM YYYY")}
            </div>
            <div className="row mx-0">
            <div
                className="col-md-9 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <iframe
                  width="100%"
                  height=""
                  src={`https://www.youtube.com/embed/${
                    vedioDetail.data && vedioDetail.data[0].slug
                  }`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              
              
              <div className="pt-2">  
                    <h5 class="head pt-2">{
                    vedioDetail.data && vedioDetail.data[0].title
                  }</h5> 
                    <div className="details1">
                    {
                    vedioDetail.data && vedioDetail.data[0].except
                  }
                    </div>
                </div>

                </div>
                <div
                className="col-md-3 animate-box sside"
                data-animate-effect="fadeInRight"
              > 
                <div className="row mx-0">
                  <div className="fh5co_heading py-2 mb-2 gbg">
                    <h5>Sponsored Content</h5>
                  </div>
                </div>
                <div className="clearfix"></div>

                {sponsorNews.data &&
                  sponsorNews.data.map((val) => {
                    return (
                      <>
                        <div className="row pb-2">
                          <Link
                            to={`/news/${val.posts_id}`}
                            onClick={() => this.displayDetail(val.posts_id)}
                          >
                            <div className="col-5 align-self-center">  
                              <img src={`${baseUrl}${val.image_small && val.image_small}`} alt={`${val  && val.post_title}`}  />
                            </div>
                            <div className="col-7 paddding">
                              <div className="most_fh5co_treding_font">
                                {val.post_title}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })} 
              </div>  




            </div>
          </div>

 
    



          
          

          <div className="container-fluid pt-3 source-data categoryNews">
          <div className="container animate-box" data-animate-effect="fadeIn">
          <div className="row mx-0">
            <div
                className="col-md-12 animate-box"
                data-animate-effect="fadeInLeft"
              > 
              <h2>Latest Videos</h2> 
            <div className="row mx-0 news-main">

            {latestNews.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {latestNews.data.map((ved) => {
           // const { id, heading, description, img, smallHeading } = item;
            return (
              <div className="item px-2"> 
                          <div className="fh5co_hover_news_img">
                          <Link
                            to={`/videos/video/${ved.videos_id}`}
                            className="postImg">
                            <div className="x-bg">
                                <div className="x-bg-layer-lower-img">
                                      <img src={`${baseUrl}${ved.image_link}`} alt="img"  />
                                </div>
                            </div>
                          </Link> 
                            <div className="pt-2"> 
                              <Link
                                to={`/videos/video/${ved.videos_id}`}
                                className="d-block fh5co_small_post_heading fh5co_small_post_heading_1"
                              >
                                <h5 class="head pt-2">{ved.title}</h5>
                              </Link> 
                            </div>
                          </div>
                    
                        </div>
            );
          })}
        </OwlCarousel>
      )}

            
  
            </div>
            </div>
        </div>
        </div>
        </div>
 









        <div className="container-fluid pt-3 source-data categoryNews globalNewsB">
          <div className="container animate-box" data-animate-effect="fadeIn">
          <div className="row mx-0">
            <div
                className="col-md-12 animate-box"
                data-animate-effect="fadeInLeft"
              > 
              <h2>Global Videos</h2> 
            <div className="row mx-0 news-main">

            {globalNewsPosts.data && (
        <OwlCarousel 
        items={4}
        className="owl-theme home-theme"
        loop
        nav
        margin={8} {...options}>
          {globalNewsPosts.data.map((ved) => {
           // const { id, heading, description, img, smallHeading } = item;
            return (
              <div className="item px-2"> 
                          <div className="fh5co_hover_news_img">
                          <Link
                             to={`/videos/video/${ved.videos_id}`}
                            className="postImg">
                            <div className="x-bg">
                                <div className="x-bg-layer-lower-img">
                                      <img src={`${baseUrl}${ved.image_link}`} alt="img"  />
                                </div>
                            </div>
                          </Link> 
                            <div className="pt-2">
                              <Link
                                to={`/videos/video/${ved.videos_id}`}
                                className="d-block fh5co_small_post_heading fh5co_small_post_heading_1"
                              >
                                <h5 class="head pt-2">{ved.title}</h5>
                              </Link> 
                            </div>
                          </div>
                    
                        </div>
            );
          })}
        </OwlCarousel>
      )}

            
  
            </div>
          </div>
        </div>
          </div>
        </div>







        </div>
        
        </>
         )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchVideo);
