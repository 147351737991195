import React from "react";
import Home from "./Views/Home/index";
import AboutUs from "./Views/AboutUs/index";
import LiveTV from "./Views/LiveTV/index";

import allVideos from "./Views/Videos/index";
import WatchVideo from "./Views/WatchVideo/index";
import SingleNews from "./Views/SingleNews/index";

//import Shows from './Views/Shows/index';
import Category from "./Views/Category/index";
import CategoryGrobalPolitics from "./Views/Category/GrobalPolitics";
import CategoryEnviornment from "./Views/Category/CategoryEnviornment";
import CategoryBusiness from "./Views/Category/CategoryBusiness";
import CategoryScienceAndTechnology from "./Views/Category/CategoryScienceAndTechnology";

//import Advertise from './Views/Advertise/index';
//import Pakistan from './Views/Pakistan/index';
//import TouristCommunity from './Views/TouristCommunity/index';
//import SingleCommunity from './Views/SingleCommunity/index';
import ContactUs from "./Views/ContactUs/index";
import Disclaimer from "./Views/Disclaimer/index";
import PrivacyPolicy from "./Views/PrivacyPolicy/index";
import TermsOfUse from "./Views/TermsOfUse/index";
//import Career from './Views/Career/index';
//import Partners from './Views/Partners/index';
//import BecomeContributor from './Views/BecomeContributor/Main/index';
import UserProfile from "./Views/UserProfile/index";

//import DiscoverShop from './Views/Shop/Main/index';
//import ViewProduct from './Views/Shop/ViewProduct/index';
//import ViewFullBag from './Views/Shop/ViewBag/index';

import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./Components/Header";
// import HeaderSearch from './Components/HeaderSearch';
import Footer from "./Components/Footer";

//import SlideDrawer from './SlideDrawer/SlideDrawer.js'
import Backdrop from "./SlideDrawer/Backdrop.js";

import "./index.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "./Redux/Actions/userActions";
import { isObjEmpty } from "./utils";
import CategoryPosts from "./Views/Category/CategoryPosts";

import Error from "./Views/404/index";
import UnderConstration from "./Views/UnderConstration/index";

class RoutesComponent extends React.Component {
  state = {
    drawerOpen: false,
    pathName: window.location.pathname,
  };

  drawerToggleClickHandler = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
    });
  };

  render() {
    let backdrop;
    if (this.state.drawerOpen) {
      backdrop = <Backdrop drawerBtn={this.drawerToggleClickHandler} />;
    }
    const { userData } = this.props;
    return (
      <div>
        <Header />

        <div className="div-body1">
          <div className="body-inner-div1">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/">
                <Redirect to="/" />
              </Route>
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/live-tv" component={LiveTV} />
              <Route
                exact
                path="/videos/video/:movie_id"
                component={WatchVideo}
              />
              <Route exact path="/videos" component={allVideos} />
              <Route exact path="/news/:post_id" component={SingleNews} />
              <Route exact path="/categories" component={Category} />
              <Route exact path="/404" component={Error} />
              <Route
                exact
                path="/underConstration"
                component={UnderConstration}
              />
              <Route
                exact
                path="/category/category-posts/:cat_id"
                component={CategoryPosts}
              />
              <Route
                exact
                path="/category/global-politics"
                component={CategoryGrobalPolitics}
              />
              <Route
                exact
                path="/category/enviornment"
                component={CategoryEnviornment}
              />
              <Route
                exact
                path="/category/business"
                component={CategoryBusiness}
              />
              <Route
                exact
                path="/category/science-and-technology"
                component={CategoryScienceAndTechnology}
              />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/disclaimer" component={Disclaimer} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/terms-Of-use" component={TermsOfUse} />
              <Route exact path="/user-profile">
                {isObjEmpty(userData) ? <Redirect to="/" /> : <UserProfile />}
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export const Routes = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutesComponent);
