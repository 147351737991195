import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { mapStateToProps } from "../../Redux/Actions/userActions";

import { Link } from "react-router-dom";
import videojs from "video.js";
import "./styles.css";
import "video.js/dist/video-js.css";
require("videojs-contrib-quality-levels");
require("videojs-max-quality-selector");
class LivePlayer extends Component {
  state = {
    //videoLink: "http://45.76.140.114:8080/hls/mystream.m3u8",
    videoLink: "https://app.viloud.tv/hls/channel/1b46775f40612b8116b3d83346fa1a8b.m3u8",    
    liveTVData: [],
  };
  componentDidMount() {
    // instantiate Video.js
    const { banner } = this.props;
    //  const baseUrl = "http://quews.smartmediahouse.tv";
    const baseUrl = "http://quews.smartmediahouse.tv";

    axios.get(`${baseUrl}/quews/Api_data/getLiveTv`).then((res) => {
      const liveTVData = res.data;

      this.setState({ liveTVData });
    });

    if (!banner) {
      this.player = videojs(
        this.videoNode,
        {
          ...this.props,
          html5: {
            hls: {
              overrideNative: true,
            },
          },
          responsive: true,
          controls: true,
          autoplay: "muted",
          preload: "auto",
        },
        function onPlayerReady() {
          // console.log('onPlayerReady', this)
        }
      );
      this.player.maxQualitySelector();
    }
  }
  render() {
    const { banner } = this.props;
    const { liveTVData, videoLink } = this.state;
    return (
      <>
        {banner && (
          <div className="live-player-div-main">
            <div
              className="live-player-inner-div"
              style={{ width: "100% !important" }}
            >
              <img src={banner} alt="Banner" />
            </div>
          </div>
        )}
        {banner === undefined && (
          <div className="live-player-div-main video-player-live">
            <div
              className="live-player-inner-div video-player-live"
              style={{ width: "100% !important" }}
            >
              <video
                autoPlay
                muted="muted"
                ref={(node) => (this.videoNode = node)}
                id="my-player"
                className="video-js"
                style={{ height: "100%", width: "100%" }}
              >
                <source src={videoLink} type="application/x-mpegURL" />
              </video>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default connect(mapStateToProps)(LivePlayer);
