import React from "react";
import axios from "axios";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import { Link } from "react-router-dom";
import LivePlayer from "../../Components/LivePlayer/index.js";
import Loader from "../../Components/Loader/index.js";

class Videos extends React.Component {
  state = {
    videoLink: "http://45.76.140.114:8080/hls/mystream.m3u8",
    vedios: [],
    baseUrl: "http://quews.smartmediahouse.tv/quews/",
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
    const baseUrl = "http://quews.smartmediahouse.tv";
    // const baseUrl = "http://quews.smartmediahouse.tv";

    axios.get(`${baseUrl}/quews/Api_data/latest_movies_get`).then((res) => {
      const vedios = res.data;
      this.setState({ vedios });
      this.setState({ loaded: false });
      
    });
  }

  render() {
    const { videoLink, vedios, baseUrl,loaded } = this.state;
    //console.log("---->", JSON.stringify(vedios));
    return (
      <>
      {loaded ? (
        <Loader isLoader={loaded}></Loader>
      ) : (
      <>
        <div className="PlayerPage">
          <div className="container-fluid fh5co_video_news_bg pb-5">
            <div className="container animate-box" data-animate-effect="fadeIn">
              <div>
                <div className="fh5co_heading pt-5 pb-2">
                  <h2>Global Videos</h2>
                </div>
              </div>
              <div className="row mx-0">
                {vedios.data &&
                  vedios.data.map((val) => {
                    return (
                      <>
                        <div className="col-md-3 col-12 paddding-l">
                          <div className="fh5co_hover_news_img"> 
                            <Link
                                to={`/videos/video/${val.id}`}
                                className="postImg">
                                <div className="x-bg">
                                    <div className="x-bg-layer-lower-img">
                                          <img src={`${baseUrl}${val.image_link}`} alt="img"  />
                                    </div>
                                </div>
                          </Link>
                            <div className="pt-2">
                              <Link
                                to={`/videos/video/${val.id}`}
                                className="d-block fh5co_small_post_heading fh5co_small_post_heading_11"
                              >
                                <span className="">{val.title}</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        
        </>
         )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
