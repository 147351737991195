import React from "react";
import axios from "axios";
import Api from "../../Api/Api.js";
import { URI } from "../../Api/index.js";
import { isNullRetNull, splitArrayIntoChunks } from "../../utils/index.js";
import Loader from "../../Components/Loader/index.js";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import Notify from "../../Components/Notify/index.js";
import { Link } from "react-router-dom";
//import Iframe from 'react-iframe'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Category extends React.Component {
  state = {
    categories: [],
    latestNews: [],
    randomNews: [],
    editorNews: [],
    topNews: [],
    baseUrl: "",
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
    const baseUrl = "http://quews.smartmediahouse.tv";
    // const baseUrl = "http://quews.smartmediahouse.tv";

    let one = `${baseUrl}/quews/Api_data/getAllCategoriesWithPost`;
    let two = `${baseUrl}/quews/Api_data/getLatestNews?limit=8`;
    let three = `${baseUrl}/quews/Api_data/getRandumNews`;
    let four = `${baseUrl}/quews/Api_data/getEditorNews`;
    let five = `${baseUrl}/quews/Api_data/getTopNews`;

    const categoriesRequest = axios.get(one);
    const latestNewsRequest = axios.get(two);
    const randomNewsRequest = axios.get(three);
    const editorNewsRequest = axios.get(four);
    const topNewRequest = axios.get(five);
    this.setState({
      baseUrl:`${baseUrl}/quews/`,  
    });

    axios
      .all([
        categoriesRequest,
        latestNewsRequest,
        randomNewsRequest,
        editorNewsRequest,
        topNewRequest,
      ])
      .then(
        axios.spread((...responses) => {
          const categoriesResponse = responses[0];
          const latestNewsResponse = responses[1];
          const randomNewsRespones = responses[2];
          const editorNewsRespones = responses[3];
          const topNewRequest = responses[4];
          this.setState({
            categories: categoriesResponse.data,
            latestNews: latestNewsResponse.data,
            randomNews: randomNewsRespones.data,
            editorNews: editorNewsRespones.data,
            topNews: topNewRequest.data,
            loaded: false
          });

          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
      });
  }

  render() {
    const { categories, latestNews, topNews, baseUrl, loaded  } = this.state;

    //console.log("topNews :::::::", JSON.stringify(topNews));

    return (
      
      <>
      {loaded ? (
        <Loader isLoader={loaded}></Loader>
      ) : (
      <>
        <div className="container paddding-l mb-5 mt mt-3">
          <div className="mb-4">
            <h2>All Categories</h2>
          </div>
          <div className="row mx-0">
            <div
              className="col-md-6 col-12 paddding-l animate-box"
              data-animate-effect="fadeIn"
            >
              <div className="fh5co_suceefh5co_height">
                
                <Link
                      to={`/news/${topNews.data && topNews.data[0].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${topNews.data && topNews.data[0].image_link}`} alt="img"  />
                          </div>
                      </div>
                </Link>
                <div className="fh5co_suceefh5co_height_position_absolute"></div>
                <div className="fh5co_suceefh5co_height_position_absolute_font">
                  <div className="">
                    <Link
                      to={`/news/${topNews.data && topNews.data[0].posts_id}`}
                      className="fh5co_good_font"
                    >
                      {topNews.data && topNews.data[0].post_title}
                    </Link>
                  </div>
                  <div className="cat">
                    <Link to="categories">All News</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div
                  className="col-md-6 col-6 paddding-l pad-b-8 animate-box"
                  data-animate-effect="fadeIn"
                >
                  <div className="fh5co_suceefh5co_height_2 mb-2">
                     
                  <Link
                      to={`/news/${latestNews.data && latestNews.data[0].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[0].image_link}`} alt="img"  />
                          </div>
                      </div>
                  </Link>


                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[0].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[0].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Pakistan</Link>
                      </div>
                    </div>
                  </div>
                  <div className="fh5co_suceefh5co_height_2">
                  <Link
                      to={`/news/${latestNews.data && latestNews.data[1].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[1].image_link}`} alt="img"  />
                          </div>
                      </div>
                  </Link>

                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[1].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[1].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Pakistan</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 col-6 paddding-l pad-b-8 animate-box"
                  data-animate-effect="fadeIn"
                >
                  <div className="fh5co_suceefh5co_height_2 mb-2">
                  <Link
                      to={`/news/${latestNews.data && latestNews.data[2].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[2].image_link}`} alt="img"  />
                          </div>
                      </div>
                  </Link>
                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[2].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[2].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Global</Link>
                      </div>
                    </div>
                  </div>
                  <div className="fh5co_suceefh5co_height_2 mb-2">
                  <Link
                      to={`/news/${latestNews.data && latestNews.data[3].posts_id}`}
                      className="postImg">
                      <div className="x-bg">
                          <div className="x-bg-layer-lower-img">
                               <img src={`${baseUrl}${latestNews.data && latestNews.data[3].image_link}`} alt="img"  />
                          </div>
                      </div>
                  </Link>
                    <div className="fh5co_suceefh5co_height_position_absolute"></div>
                    <div className="fh5co_suceefh5co_height_position_absolute_font_2">
                      <div className="">
                        <Link
                          to={`/news/${
                            latestNews.data && latestNews.data[3].posts_id
                          }`}
                          className="fh5co_good_font_2"
                        >
                          {latestNews.data && latestNews.data[3].post_title}
                        </Link>
                      </div>
                      <div className="cat">
                        <Link to="news">Global</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-3 source-data">
          <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="row mx-0 news-main">
              {categories.data &&
                categories.data.map((cat) => {
                  return (
                    <>
                      <div className="col-md-3 col-12 paddding-l">
                        <Link to={`/category/category-posts/${cat.cat_id}`} className="postImg"> 
                              <div className="x-bg">
                                  <div className="x-bg-layer-lower-img">
                                      <img src={`${baseUrl}${cat.image_link && cat.image_link}`} alt="img"  />
                                  </div>
                              </div> 
                          <h6>{cat.title}</h6>
                          <p>{cat.post_title}</p>
                        </Link>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
 
        </>
         )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);
