import React from "react";
import axios from "axios";
import moment from "moment";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader/index.js";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Advertise extends React.Component {
  state = {
    detail: [],
    sponsorNews: [],
    baseUrl:"",
    loaded: false,
  };

  componentDidMount() {
    this.setState({ loaded: true });
    const baseUrl = "http://quews.smartmediahouse.tv";
    // const baseUrl = "http://quews.smartmediahouse.tv";

    const post_id = this.props.match.params.post_id;
    this.setState({
      baseUrl:`${baseUrl}/quews/`,  
    });  
    axios
      .get(`${baseUrl}/quews/Api_data/getPostById?post_id=${post_id}`)
      .then((res) => {
        const detail = res.data;
        this.setState({ detail });
        this.setState({ loaded: false });
      });

    axios.get(`${baseUrl}/quews/Api_data/getLatestNews?limit=8`).then((res) => {
      const sponsorNews = res.data;
      this.setState({ sponsorNews });
    });
    window.scrollTo(0, 0);
  }

  setStateObj(obj) {
    this.setState({ ...this.state, ...obj });
  }

  displayDetail(post_id) {
    // const post_id = this.props.match.params.post_id;
    const baseUrl = "http://quews.smartmediahouse.tv";
    axios
      .get(`${baseUrl}/quews/Api_data/getPostById?post_id=${post_id}`)
      .then((res) => {
        const detail = res.data;
        this.setState({ detail });
        
      });
    window.scrollTo(0, 0);
  }

  render() {
    const { detail, sponsorNews, baseUrl,loaded } = this.state;
   // console.log(detail);
    return (
    <>
      {loaded ? (
        <Loader isLoader={loaded}></Loader>
      ) : (
      <>
        <div className="container-fluid pb-4 pt-2 paddding">
          <div className="container paddding">
            <div
              className="row mx-0 padd-l date mt-2 mb-2"
              style={{ textAlign: "left", paddingLeft: "15px" }}
            >
              {detail.data && ReactHtmlParser(detail.data[0].post_at)} 
            </div>
            <div className="row mx-0">
              <div
                className="col-md-9 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="row pb-4">
                  <div className="col-md-12">
                    <div className="fh5co_hover_news_img">
                      <img
                        style={{ width: "100%" }}
                        src={`${baseUrl}${detail.data && detail.data[0].image_link}`}
                        alt={`${detail.data && detail.data[0].post_title}`}
                      /> 
                    </div>
                    <div className="pt-4 single-heading">
                      <h2>{detail.data && detail.data[0].post_title}</h2>
                      <h6>
                        <small>
                          <b>Posted By Quews</b>
                        </small>
                      </h6>
                      <hr />
                      {detail.data && ReactHtmlParser(detail.data[0].content)}
                     
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-3 animate-box sside"
                data-animate-effect="fadeInRight"
              >
                <div className="row mx-0">
                  <div className="fh5co_heading py-2 mb-2 gbg">
                    <h5>Sponsored Content</h5>
                  </div>
                </div>
                <div className="clearfix"></div>

                {sponsorNews.data &&
                  sponsorNews.data.map((val) => {
                    return (
                      <>
                        <div className="row pb-2">
                          <Link
                            to={`/news/${val.posts_id}`}
                            onClick={() => this.displayDetail(val.posts_id)}
                          >
                            <div className="col-5 align-self-center">  
                              <img src={`${baseUrl}${val.image_small && val.image_small}`} alt={`${val  && val.post_title}`}  />
                            </div>
                            <div className="col-7 paddding">
                              <div className="most_fh5co_treding_font">
                                {val.post_title}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div> 
        </>
         )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Advertise);
