import React from "react";
import axios from "axios";
import moment from "moment";
import "./styles.css";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions.js";
import { Link } from "react-router-dom";

class UnderConstration extends React.Component {
  render() {
    return (
      <>
        <div className="container-fluid pb-4 pt-2 paddding">
          <div className="container paddding">
            <div className="row mx-0">
              <div className="row">
                <div className="col-lg-12 col-12" style={{ margin: "0 auto" }}>
                  <div className="card card-advertise col-lg-12 col-md-9 col-12 m-auto py-4">
                    <div className="card-body">
                      <center className="mt-4">
                        <h3 class="card-header display-1 text-muted text-center">
                          Page Under Constration
                        </h3>

                        <span class="card-subtitle mb-2 text-muted text-center">
                          This Page is Under Constration. We Are Comming Soom.
                          Please Keep Browsing...
                        </span>

                        <div class="card-body mx-auto">
                          <a
                            type="button"
                            href="#"
                            class="btn btn-sm btn-info text-white"
                          >
                            {" "}
                            Back To Home{" "}
                          </a>
                        </div>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnderConstration);
