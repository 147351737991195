import React, { Component } from 'react';
import './styles.css'
import { facebookb, instab, twitterb, youtubeb,whitelogo } from '../../constant/images';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/Actions/userActions';   

class Footer extends Component {
    state = {
        list:[
            { name:"Home", link:"/" },
            { name:"Terms of Use", link:"terms-of-use" },
            { name:"Privacy Policy", link:"privacy-policy" },
            { name:"Disclaimer", link:"disclaimer" },
            { name:"Career", link:"career" },
            { name:"Contact Us", link:"contact-us" },
            { name:"About Us", link:"about-us" },
        ]
    }

    render(){
        const { list } = this.state;
        return (<>
        
			   
<div className="footer-wrap">     
 
<div className="container-fluid fh5co_footer_bg pb-3">
    <div className="container animate-box">
        <div className="row">
            <div className="col-12 py-5 mm">
              <Link to="/" className="footer-brand"><img src={whitelogo} className="footer_logo" alt="pic" /></Link>
			   <p> We are a news service born out of a global  loss of confidence in media and destabilising polarisation of attitudes. We believe it is possible, indeed necessary, to create a public forum where vital issues of the day are discussed, debated, analysed and circulated.</p>
			</div>
            <div className="clearfix"></div>
		</div>	
			
			<div className="row">
			
			
            <div className="col-md-8">
                <div className="footer_main_title py-3 bb-1 mb-3">Explore QUEWS</div>
				
				
                  <div className="row">
				    <div className="col-md-3">
					   <ul className="footer_menu">
                    <li><Link to="/about-us" className="">About QUEWS</Link></li>
                    <li><Link to="/about-us" className="">Community Guidlines</Link></li>
                    <li><Link to="/about-us" className="">Ads & Marketing</Link></li>
                    <li><Link to="/about-us" className="">Work for us</Link></li>
                    <li><Link to="/about-us" className="">Sponsorship</Link></li>
                   
                    
                </ul>
					</div>
					
					 <div className="col-md-3">
					   <ul className="footer_menu">
                    <li><Link to="/about-us" className="">Global Politics</Link></li>
                    <li><Link to="/about-us" className="">Health & Well being</Link></li>
                    <li><Link to="/about-us" className="">Science and Technology</Link></li>
                    <li><Link to="/about-us" className="">Today in History</Link></li>
                    <li><Link to="/about-us" className="">Politics</Link></li>
                   
                </ul>
					</div>
					
					 <div className="col-md-3">
					   <ul className="footer_menu">
                   <li><Link to="/about-us" className="">Today's videos</Link></li>
                    <li><Link to="/about-us" className="">Entertainment</Link></li>
                    <li><Link to="/about-us" className="">Health</Link></li>
                    <li><Link to="/about-us" className="">Politics</Link></li>
                    <li><Link to="/about-us" className="">Lifestyle</Link></li>
                   
                </ul>
					</div>
					
					 <div className="col-md-3">
					   <ul className="footer_menu">
                    <li><Link to="/about-us" className="">Global Politics</Link></li>
                    <li><Link to="/about-us" className="">Health & Well being</Link></li>
                    <li><Link to="/about-us" className="">Science and Technology</Link></li>
                    <li><Link to="/about-us" className="">Today in History</Link></li>
                    
                    
                </ul>
					</div>
				  </div>
                
            </div>
            <div className="col-12 col-md-2 col-lg-2">
                <div className="footer_main_title py-3 bb-1 mb-3">Contact QUEWS</div>
				
                <ul className="footer_menu">
				<li><i className="fa fa-map-marker"></i> Unit 3
108-114 Golders Green Rd
LONDON NW11 8HB</li>
                    <li><Link to="tel:44 203 637 7134" className=""><i className="fa fa-phone"></i> +44 203 637 7134</Link></li>
                    <li><Link to="mailto:hello@quews.news" className=""><i className="fa fa-envelope-o"></i> hello@quews.news</Link></li>
                    
                </ul>
            </div>
			
			<div className="col-12 col-md-2 col-lg-2">
			  <div className="footer_main_title py-3 bb-1 mb-3">Follow QUEWS</div>
			     <p> Receive our Newsletter, personalised to your preferences</p>
			  <div className="footer_mediya_icon">
                    <div className="text-center d-inline-block"><Link to="/about-us" className="fh5co_display_table_footer">
                        <div className="fh5co_verticle_middle"><i className="fa fa-linkedin"></i></div>
                    </Link></div>
                    <div className="text-center d-inline-block"><Link to="/about-us" className="fh5co_display_table_footer">
                        <div className="fh5co_verticle_middle"><i className="fa fa-google-plus"></i></div>
                    </Link></div>
                    <div className="text-center d-inline-block"><Link to="/about-us" className="fh5co_display_table_footer">
                        <div className="fh5co_verticle_middle"><i className="fa fa-twitter"></i></div>
                    </Link></div>
                    <div className="text-center d-inline-block"><Link to="/about-us" className="fh5co_display_table_footer">
                        <div className="fh5co_verticle_middle"><i className="fa fa-facebook"></i></div>
                    </Link></div>
                </div>
			</div>
          </div> 
           
       
    </div>
</div>
<div className="container-fluid fh5co_footer_right_reserved">
    <div className="container">
        <div className="row  ">
            <div className="col-12 col-md-8 py-4 Reserved">© Copyright 2022 QUEWS News. Rights reserved.Quews is not responsible for the content of external sites. </div>
            <div className="col-12 col-md-4 spdp_right py-4">
                <Link to="/" className="footer_last_part_menu">Home</Link>
                <Link to="/about-us" className="footer_last_part_menu">About</Link>
                <Link to="/contact-us" className="footer_last_part_menu">Contact</Link>
                <Link to="/latest-news" className="footer_last_part_menu">Latest News</Link>
            </div>
        </div>
    </div>
</div> 
<div className="gototop js-top">
    <Link to="#" className="js-gotop"><i className="fa fa-arrow-up"></i></Link>
</div>

</div>


 
        </>)
    }   
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)