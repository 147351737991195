import React from 'react'
import Api from '../../Api/Api.js';
import { URI } from '../../Api/index.js';
import { isNullRetNull, splitArrayIntoChunks } from '../../utils/index.js';
import Loader from '../../Components/Loader/index.js';
import './styles.css';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/Actions/userActions.js';
import Notify from '../../Components/Notify/index.js';
import { Link } from 'react-router-dom';
//import Iframe from 'react-iframe'
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 


class CategoryBusiness extends React.Component {
     

    render(){
       
      return(<>
     



<div className="container-fluid pt-3 source-data">
    <div className="container animate-box" data-animate-effect="fadeIn">
            <div className="mb-4">
			 <h2>Global Business</h2>
			</div>
      <div className="row mx-0 news-main">
        <div className="col-md-3 col-12 paddding-l">
		 <Link  to="/single">
		 <img src={require('../../assets/images/AP21302341170864-400x267.jpg')} /> 
		   <h6>Israel, touting technology, aims for zero emissions by 2050</h6>
		   <p>Israel said Friday its hope to reach zero greenhouse gas emissions by 2050</p>
		 </Link>  
		</div>
		
		<div className="col-md-3 col-12 paddding-l">
		<Link  to="/single">
		 <img src={require('../../assets/images/21302268848776-400x267.jpg')} /> 
		   <h6>G20 leaders to tackle energy prices, other economic woes</h6>
		   <p>Leaders of the Group of 20 countries gathering for their first in-person summit since the pandemic took hold</p>
		 </Link>		 
		</div>
		
		<div className="col-md-3 col-12 paddding-l">
			<Link  to="/single">
			 <img src={require('../../assets/images/image-3-400x256.png')} /> 
			   <h6>Italy hosts a climate-focused G20 as geopolitics shift</h6>
			   <p>The leaders of Russia and China aren't coming. Turkey nearly set off a diplomatic incident on the</p>
			 </Link>  
		</div>
		
		<div className="col-md-3 col-12 paddding-l">
			<Link  to="/single">
			 <img src={require('../../assets/images/image-3-400x256.png')} /> 
			   <h6>Italy hosts a climate-focused G20 as geopolitics shift</h6>
			   <p>The leaders of Russia and China aren't coming. Turkey nearly set off a diplomatic incident on the</p>
			 </Link>  
		</div>
		
		<div className="col-md-3 col-12 paddding-l">
			<Link  to="/single">
			 <img src={require('../../assets/images/image-3-400x256.png')} /> 
			   <h6>Italy hosts a climate-focused G20 as geopolitics shift</h6>
			   <p>The leaders of Russia and China aren't coming. Turkey nearly set off a diplomatic incident on the</p>
			 </Link>  
		</div>
		
		<div className="col-md-3 col-12 paddding-l">
			<Link  to="/single">
			 <img src={require('../../assets/images/image-3-400x256.png')} /> 
			   <h6>Italy hosts a climate-focused G20 as geopolitics shift</h6>
			   <p>The leaders of Russia and China aren't coming. Turkey nearly set off a diplomatic incident on the</p>
			 </Link>  
		</div>
		
		<div className="col-md-3 col-12 paddding-l">
			<Link  to="/single">
			 <img src={require('../../assets/images/image-3-400x256.png')} /> 
			   <h6>Italy hosts a climate-focused G20 as geopolitics shift</h6>
			   <p>The leaders of Russia and China aren't coming. Turkey nearly set off a diplomatic incident on the</p>
			 </Link>  
		</div>
		
		<div className="col-md-3 col-12 paddding-l">
		<Link  to="/single">
		 <img src={require('../../assets/images/climate-change-pakiston-400x225.jpg')} /> 
		   <h6>Climate change, environmental problems put government in a bind</h6>
		   <p>Environmentalists say Pakistan is particularly vulnerable to the effects of climate change</p>
		</Link>   
		</div>
	  </div>	
    </div>
</div>



 

      </>)
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBusiness);