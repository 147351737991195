const facebook = require('./../assets/header-icons/fb.png');
const insta = require('./../assets/header-icons/insta.png');
const twitter = require('./../assets/header-icons/twitter.png');
const youtube = require('./../assets/header-icons/youtube.png');

const facebookb = require('./../assets/footer-icons/fbb.png');
const instab = require('./../assets/footer-icons/instab.png');
const twitterb = require('./../assets/footer-icons/twitterb.png');
const youtubeb = require('./../assets/footer-icons/youtubeb.png');

const profile = require('./../assets/header-icons/profile.png');
const profileMobile = require('./../assets/header-icons/Green-Login-Icon.png');
const ShopBanner = require('./../assets/shop-banner.png');
const ContactUsBanner = require('./../assets/contact-banner.jpg');
const DisclaimerBanner = require('./../assets/disclaimer-banner.jpg');
const PrivacyPolicyBanner = require('./../assets/privacy-banner.jpg');
const TermsOfUseBanner = require('./../assets/terms.jpg');
const CareerBanner = require('./../assets/career-banner.jpg');
const careerShowcase = require('./../assets/career.png');
const Bg01Banner = require('./../assets/bg01.jpg');
/*const BcBackground = require('./../assets/bc-bg.jpg');*/

const logo01 = require('./../assets/logo.png');
const whitelogo = require('./../assets/white_logo.png'); 
const sideBarLogo = require('./../assets/header-icons/Side-Bar-Logo.png');

const showHeader = require('./../assets/header-icons/show.png');
const discover_shop = require('./../assets/header-icons/discover_shop.png');
const partner = require('./../assets/header-icons/partner.png');
const advertise = require('./../assets/header-icons/advertise.png');
const pakistan = require('./../assets/header-icons/pakistan.png');
const itrip = require('./../assets/header-icons/itrip.png');
const tourist_community = require('./../assets/header-icons/tourist_community.png');
const contributor = require('./../assets/header-icons/contributor.png');
const contactuspng = require('./../assets/header-icons/contactuspng.png');
 

export {
    facebook,
    insta,
    twitter,
    youtube,
    profile,
    profileMobile,

    facebookb,
    instab,
    twitterb,
    youtubeb,

    logo01,
    whitelogo, 
    sideBarLogo,
    ShopBanner,
    ContactUsBanner,
    DisclaimerBanner,
    PrivacyPolicyBanner,
    TermsOfUseBanner,
    CareerBanner,
    careerShowcase,
    Bg01Banner,
    /*BcBackground,*/

    showHeader,
    discover_shop,
    partner,
    advertise,
    pakistan,
    itrip,
    tourist_community,
    contributor,
    contactuspng,
  

}