import React, { Component } from "react";
import axios from "axios";
//import './styles.css';
//import './assets/css/styles.css';
//import { GoogleStore, IosStore } from '../../constant/svg';
import { isNullRetNull, isObjEmpty } from "../../utils";
import { profile } from "../../constant/images";
import AuthModal from "../AuthModal";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../Redux/Actions/userActions";
import { Link } from "react-router-dom";
import { logo01 } from "../../constant/images";
import HeaderSearch from "../../Components/HeaderSearch/index.js";

class Header extends Component {
  state = {
    menus: [],
  };

  componentDidMount() {
    const baseUrl = "http://quews.smartmediahouse.tv";
    //  const baseUrl = "http://quews.smartmediahouse.tv";

    axios.get(`${baseUrl}/quews/Api_data/getMenus`).then((res) => {
      const menus = res.data;
      this.setState({ menus });
    });
  }

  render() {
    const { userData, setLoginStatus, setUserData, setNotify } = this.props;
    const { menus } = this.state;
    return (
      <>
        <div className="container-fluid bg-green fh5co_padd_mediya padding_786 top-menu">
        <div className="container">
          <nav className="navbar navbar-toggleable-md navbar-light ">
            <div className="showonMobile navbarSupportedContent">
              <ul className="navbar-nav  mr-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    style={{ paddingTop: "0px" }}
                    to="/live-tv"
                  >
                    <i className="fa fa-dot-circle-o"></i> Watch TV
                  </Link>
                </li>
              </ul>
            </div>
            <button
              className="navbar-toggler navbar-toggler-right mt-3"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="fa fa-bars"></span>
            </button>
            <Link to="/" className="navbar-brand">
              <img src={logo01} className="mobile_logo_width" alt="pic" />
            </Link>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mr-auto">
                {/* <li className="nav-item">{<HeaderSearch />}</li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/categories">
                    All categories
                  </Link>
                </li>

                {menus.data &&
                  menus.data.map((val) => {
                    return (
                      <>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`/category/category-posts/${val.genre_id}`}
                          >
                            {val.name}
                          </Link>
                        </li>
                      </>
                    );
                  })}
                <li className="nav-item ">
                  <Link className="nav-link" to="/videos">
                    Videos
                  </Link>
                </li>
                
                <li className="nav-item dropdown"> 
                           <Link className="nav-link dropdown-toggle"  id="dropdownMenuButton2" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false" to="#">More</Link>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink_1"> 
                        <Link  className="dropdown-item" to="/underConstration"> Science</Link>
                        <Link  className="dropdown-item" to="/underConstration">Weather</Link> 
                        </div>
                    </li>
              </ul>
              <ul className="navbar-nav ml-auto icons">
                {/* <li className="nav-item ">
                  <Link className="nav-link" to="/videos">
                    <i className="fa fa-video-camera"></i>
                  </Link>
                </li> */}

                {/* <li className="nav-item">
                  <Link className="nav-link" to="/categories">
                    <i className="fa fa-th"></i>
                  </Link>
                </li> */}
                <li className="nav-item hideonMobile">
                  <Link className="nav-link watchLive" to="/live-tv">
                    <i className="fa fa-dot-circle-o"></i> Watch Live
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/underConstration">
                    <i className="fa fa-user-circle-o"></i>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/404">
                    <i className="fa fa-search"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
