export const SET_LOADER_STATUS = "SET_LOADER_STATUS";
export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS"; 
export const SET_AUTH_MODAL = "SET_AUTH_MODAL";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_COMMUNITIES = "SET_COMMUNITIES";
export const SET_COMMUNITIES_COMMENTS = "SET_COMMUNITIES_COMMENTS";
export const SET_CART_LIST = "SET_CART_LIST";
export const SET_NOTIFY = "SET_NOTIFY";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const SET_CART_TOTAL_AMOUNT = "SET_CART_TOTAL_AMOUNT";
export const SET_PARTNERS_LIST = "SET_PARTNERS_LIST";
